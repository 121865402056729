import DryingTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/drying/DryingTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinAppearance/Drying/DryingTemplate'
};

const createStory = props => () => ({
  components: { DryingTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <drying-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  dryingSkin: AMOUNT.CONSIDERABLY,
  dryOpenPores: 'yes'
});
